import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import getStripe from '../GetStripe';
import '../css/BookClubRegistration.css'

const studentGradeLevels = [
    { value: "6", label: "6th" },
    { value: "7", label: "7th" },
    { value: "8", label: "8th" },
    { value: "9", label: "9th" },
    { value: "10", label: "10th" }
]

const studentReadingLevels = [
    { value: "above", label: "Above Level" },
    { value: "on", label: "On Level" },
    { value: "below", label: "Below Level" }
]

function BookClubRegistration() {

    const sigCanvas = useRef({})

    const [formData, setFormData] = useState({
        studentFullName: '',
        studentDOB: '',
        address: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        studentGradeLevel: '',
        studentReadingLevel: '',
        parentGuardianFullName: '',
        contactNumber: '',
        contactEmail: '',
        cancellationPolicy: ''
    })

    const clear = () => {
        sigCanvas.current.clear();
    };

    const save = () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        console.log(signature); // This eventually needs to send to a database.
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };    

    async function handleCheckout(event) {
        event.preventDefault();
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        console.log('Form Data: ', formData)
        const completeFormData = {
                ...formData,
                signature
        };
        console.log('Complete Form Data: ', completeFormData);
        try {
                const response = await fetch('https://myliteracycenter.pythonanywhere.com/forms/bookclub-registration/', {
                        method: 'POST',
                        headers: {
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(completeFormData)
                });
        
                if (response.ok) {
                        console.log('Form submitted successfully');
                        alert("Thank you; your form was submitted successfully!")
                } else {
                        console.error('Error submitting form');
                }
        } catch (error) {
                console.error('Error submitting form:', error);
        }
        const stripe = await getStripe()
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: 'price_1PsRkYRvLFdAUSWUEqbYWwMu', quantity: 1 }],
            mode: 'payment',
            successUrl: window.location.origin + '/',
            cancelUrl: window.location.origin + '/',
            customerEmail: formData.contactEmail,
        })

        if (error) {
            console.error('Checkout error: ', error.message)
            alert("Checkout error: " + error.message)
        }
    }

    return (
        <div className="container-fluid" style={{ padding: "0px" }}>
            
            <div className='row'>
                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-10 d-flex justify-content-center align-items-center'>

                    <div className="row flex-grow-1" style={{ paddingTop: "20px", paddingBottom: "60px" }}>
                        <hr 
                            style={{ 
                                width: "90%", 
                                margin: "auto", 
                                height: "5px",
                                border: "none",
                                backgroundColor: "#36454F",
                                opacity: "1"
                            }}
                        ></hr>
                        <h1 
                            className="responsive-h1 form-title" 
                            style={{ paddingTop: "60px", paddingBottom: "30px" }}
                        >
                            Book Club Registration
                        </h1>
                        <form onSubmit={handleCheckout} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                            <div className="row" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="studentFullName" className="form-text">Student Full Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="studentFullName"
                                            name="studentFullName"
                                            value={formData.studentFullName}
                                            onChange={handleChange} 
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="studentDOB" className="form-text">Student Date of Birth</label>
                                        <input type="date" onChange={handleChange} className="form-control" name='studentDOB' id="studentDOB" value={formData.studentDOB} />
                                    </div>
                                </div>
                            </div>
                            <p className='form-text'>Student Address</p>
                            <div className='form-group'>
                                <label htmlFor="address" className='form-text'>Street Address</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id="address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form-group' style={{ paddingTop: "20px" }}>
                                <label htmlFor='addressLine2' className='form-text'>Address Line 2</label>
                                <input 
                                    type="text"
                                    className='form-control'
                                    id='addressLine2'
                                    name="addressLine2"
                                    value={formData.addressLine2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='row' style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='city' className='form-text'>City</label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            id='city'
                                            name='city'
                                            value={formData.city}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='state' className='form-text'>State</label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            id='state'
                                            name='state'
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ paddingBottom: "20px" }}>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='zip' className='form-text'>Postal / Zip Code</label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            id='zip'
                                            name='zip'
                                            value={formData.zip}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='country' className='form-text'>Country</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='country'
                                            name='country'
                                            value={formData.country}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ paddingBottom: "20px" }}>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label htmlFor="studentGradeLevel" className="form-text">Student Grade Level</label>
                                        <select className="form-control" id="studentGradeLevel" onChange={handleChange} name="studentGradeLevel" value={formData.studentGradeLevel}>
                                            <option value="">- Select -</option>
                                            {studentGradeLevels.map(gradeLevel => (
                                                <option key={gradeLevel.value} value={gradeLevel.value}>{gradeLevel.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="form-group">
                                        <label htmlFor="studentReadingLevel" className="form-text">Student Reading Level</label>
                                        <select className="form-control" id="studentReadingLevel" onChange={handleChange} name="studentReadingLevel" value={formData.studentReadingLevel}>
                                            <option value="">- Select -</option>
                                            {studentReadingLevels.map(readingLevel => (
                                                <option key={readingLevel.value} value={readingLevel.value}>{readingLevel.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group' style={{ paddingBottom: "20px" }}>
                                <label htmlFor='parentGuardianFullName' className='form-text'>Parent/Guardian Full Name</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    id='parentGuardianFullName'
                                    name='parentGuardianFullName'
                                    value={formData.parentGuardianFullName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='row' style={{ paddingBottom: "20px" }}>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='contactNumber' className='form-text'>Contact Number</label>
                                        <input
                                            type='number'
                                            name='contactNumber'
                                            id='contactNumber'
                                            className='form-control'
                                            value={formData.contactNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='contactEmail' className='form-text'>Contact Email</label>
                                        <input 
                                            type='email'
                                            name='contactEmail'
                                            id='contactEmail'
                                            className='form-control'
                                            value={formData.contactEmail}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="cancellationPolicy" 
                                        onChange={handleChange}
                                        name="cancellationPolicy" 
                                        checked={formData.cancellationPolicy}
                                        required
                                    />
                                    <label className="form-check-label" htmlFor="cancellationPolicy">
                                        I understand that this 4 day book club is a session for students who enjoy reading and familiar with analyzing and discussing what is read. I understand that I will not be reimbursed for any meetings my child may miss. I understand that my child will be expected to participate to gain the best experience. I also understand that the book will be provided, and my child will be responsible to bring the book to each meeting.
                                    </label>
                                </div>
                            </div>
                            <div className="form-group" style={{ paddingTop: "20px" }}>
                                <label className='form-text'>Signature:</label>
                                <SignatureCanvas
                                    penColor='black'
                                    canvasProps={{className: 'signatureCanvas'}}
                                    ref={sigCanvas}
                                />
                                <button 
                                    type="button" 
                                    className="btn btn-secondary" 
                                    onClick={clear}
                                    style={{ marginRight: "10px" }}
                                >
                                    Clear Signature
                                </button>
                                <button type="button" className="btn btn-primary" onClick={save}>Save Signature</button>
                            </div>
                            <button 
                                type="submit" 
                                className="btn btn-success"
                                style={{ marginTop: "40px" }}
                            >
                                Register & Pay $20
                            </button>
                        </form>
                    </div>

                </div>
                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

        </div>

    )
}

export default BookClubRegistration