import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import getStripe from '../GetStripe';

import "../css/EssaySubmissionForm.css"

function EssaySubmissionForm() {

        const sigCanvas = useRef({});

        const clear = () => { sigCanvas.current.clear() }

        const save = () => {
                const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
                console.log(signature)
        }

        const [formData, setFormData] = useState({
                fullName: '',
                dateOfBirth: '',
                phone: '',
                email: '',
                subjectContentArea: '',
                cancellationPolicy: '',
                essayUpload: null,
                essayGuidelinesInstructionsUpload: null,
                specialInstructionsOrRequests: ''
        })

        const handleChange = (event) => {
                const { name, value, type, checked } = event.target;
                setFormData(prevState => ({
                    ...prevState,
                    [name]: type === 'checkbox' ? checked : value
                }));
            };  

        const handleEssayUpload = (event) => {
                setFormData(prevState => ({
                        ...prevState,
                        essayUpload: event.target.files[0]
                }))
        }

        const handleEssayGuidelinesInstructionsUpload = (event) => {
                setFormData(prevState => ({
                        ...prevState,
                        essayGuidelinesInstructionsUpload: event.target.files[0]
                }))
        }

        async function handleCheckout(event) {
                event.preventDefault();

                const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
                
                const formDataToSend = new FormData();
                formDataToSend.append('fullName', formData.fullName);
                formDataToSend.append('dateOfBirth', formData.dateOfBirth);
                formDataToSend.append('phone', formData.phone);
                formDataToSend.append('email', formData.email);
                formDataToSend.append('subjectContentArea', formData.subjectContentArea);
                formDataToSend.append('cancellationPolicy', formData.cancellationPolicy ? 'True' : 'False');
                formDataToSend.append('specialInstructionsOrRequests', formData.specialInstructionsOrRequests);
                formDataToSend.append('signature', signature);
            
                if (formData.essayUpload) {
                    formDataToSend.append('essayUpload', formData.essayUpload);
                }
                if (formData.essayGuidelinesInstructionsUpload) {
                    formDataToSend.append('essayGuidelinesInstructionsUpload', formData.essayGuidelinesInstructionsUpload);
                }
            
                try {
                    const response = await fetch('https://myliteracycenter.pythonanywhere.com/forms/essay-submission/', {
                        method: 'POST',
                        body: formDataToSend 
                    });
            
                    if (response.ok) {
                        console.log('Form submitted successfully');
                        alert("Thank you; your form was submitted successfully!");
                    } else {
                        console.error('Error submitting form');
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                }
                const stripe = await getStripe()
                const { error } = await stripe.redirectToCheckout({
                        lineItems: [{ price: 'price_1PskSfRvLFdAUSWUXQjiIiZo', quantity: 1 }], 
                        mode: 'payment',
                        successUrl: window.location.origin + '/',
                        cancelUrl: window.location.origin + '/',
                        customerEmail: formData.parentEmail,
                })
        
                if (error) {
                        console.error('Checkout error: ', error.message)
                        alert("Checkout error: " + error.message)
                }
        }

        return (
                <div className='container-fluid' style={{ padding: "0px" }}>

                        <div className='row'>

                        <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>

                        <div className='col-10'>

                                <div className='row' style={{ paddingTop: "20px", paddingBottom: "40px" }}>
                                        <hr 
                                                style={{ 
                                                        width: "90%", 
                                                        margin: "auto", 
                                                        height: "5px",
                                                        border: "none",
                                                        backgroundColor: "#36454F",
                                                        opacity: "1"
                                                }}
                                        ></hr>
                                        <h1 
                                                className="responsive-h1 form-title" 
                                                style={{ paddingTop: "30px", paddingBottom: "30px" }}
                                        >
                                                Essay Submission Form
                                        </h1>

                                </div>

                                <div className='row'>
                                        <form onSubmit={handleCheckout} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                                                <div className='row' style={{ paddingBottom: "20px" }}>
                                                        <div className='col-6'>
                                                                <div className='form-group'>
                                                                        <label htmlFor='fullName' className='form-text'>Full Name</label>
                                                                        <input
                                                                                type='text'
                                                                                name='fullName'
                                                                                id='fullName'
                                                                                className='form-control'
                                                                                onChange={handleChange}
                                                                                value={formData.formData}
                                                                        />
                                                                </div>
                                                        </div>
                                                        <div className='col-6'>
                                                                <div className='form-group'>
                                                                        <label htmlFor='dateOfBirth' className='form-text'>Date of Birth</label>
                                                                        <input
                                                                                type='date'
                                                                                className='form-control'
                                                                                name='dateOfBirth'
                                                                                id='dateOfBirth'
                                                                                value={formData.dateOfBirth}
                                                                                onChange={handleChange}
                                                                        />
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className='row' style={{ paddingBottom: "20px" }}>
                                                        <div className='col-6'>
                                                                <div className='form-group'>
                                                                        <label htmlFor='phone' className='form-text'>Phone</label>
                                                                        <input
                                                                                type='tel'
                                                                                name='phone'
                                                                                id='phone'
                                                                                value={formData.phone}
                                                                                onChange={handleChange}
                                                                                className='form-control'
                                                                        />
                                                                </div>
                                                        </div>
                                                        <div className='col-6'>
                                                                <div className='form-group'>
                                                                        <label htmlFor='email' className='form-text'>Email</label>
                                                                        <input
                                                                                type='email'
                                                                                name='email'
                                                                                id='email'
                                                                                onChange={handleChange}
                                                                                value={formData.email}
                                                                                className='form-control'
                                                                        />
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className='form-group' style={{ paddingBottom: "20px" }}>
                                                        <label htmlFor='subjectContentArea' className='form-text'>Subject/Content Area</label>
                                                        <input
                                                                type='text'
                                                                name='subjectContentArea'
                                                                className='form-control'
                                                                value={formData.subjectContentArea}
                                                                onChange={handleChange}
                                                                id='subjectContentArea'
                                                        />
                                                </div>
                                                <div className='form-group'>
                                                        <input
                                                                type='file'
                                                                className='form-control'
                                                                id='essayUpload'
                                                                name='essayUpload'
                                                                style={{ display: "none" }}
                                                                onChange={handleEssayUpload}
                                                                accept=".pdf,.doc,.docx"
                                                        />
                                                        <button
                                                                onClick={() => document.getElementById('essayUpload').click()}
                                                                className='btn btn-primary'
                                                                style={{ backgroundColor: "purple", border: "0px", marginBottom: "20px" }}
                                                        >
                                                                Essay Upload
                                                        </button>
                                                </div>
                                                <div className='form-group'>
                                                        <input
                                                                type='file'
                                                                className='form-control'
                                                                id='essayGuidelinesInstructionsUpload'
                                                                name='essayGuidelinesInstructionsUpload'
                                                                style={{ display: "none" }}
                                                                onChange={handleEssayGuidelinesInstructionsUpload}
                                                                accept='.pdf,.doc,.docx'
                                                        />
                                                        <button
                                                                onClick={() => document.getElementById('essayGuidelinesInstructionsUpload').click()}
                                                                className='btn btn-primary'
                                                                style={{ backgroundColor: "purple", border: "0px", marginBottom: "20px" }}
                                                        >
                                                                Upload Guidelines/Instructions
                                                        </button>
                                                </div>
                                                <div className='form-group'>
                                                        <label className='form-text' htmlFor='specialInstructionsOrRequests'>Special Instructions or Requests</label>
                                                        <textarea
                                                                name='specialInstructionsOrRequests'
                                                                id="specialInstructionsOrRequests"
                                                                value={formData.specialInstructionsOrRequests}
                                                                onChange={handleChange}
                                                                className='form-control'
                                                                rows="4"
                                                        />
                                                </div>
                                                <div className='form-group' style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                                        <div className='form-check'>
                                                                <input
                                                                        type='checkbox'
                                                                        className='form-check-input'
                                                                        id='cancellationPolicy'
                                                                        name='cancellationPolicy'
                                                                        checked={formData.cancellationPolicy}
                                                                        onChange={handleChange}
                                                                        required
                                                                />
                                                                <label className='form-check-label' htmlFor='cancellationPolicy'>
                                                                        I understand that it may take 3-5 days for my essay to be reviewed and that I 
                                                                        am required to pay the cost of editing 1 page to submit my essay. Full payment 
                                                                        must be received before essay editing is completed.
                                                                </label>
                                                        </div>
                                                </div>
                                                <div className='form-group'>
                                                        <label className='form-text'>Signature</label>
                                                        <SignatureCanvas
                                                                penColor='black'
                                                                canvasProps={{ className: 'signatureCanvas' }}
                                                                ref={sigCanvas}
                                                        />
                                                        <button
                                                                type='button'
                                                                className='btn btn-secondary'
                                                                onClick={clear}
                                                                style={{ marginRight: "10px" }}
                                                        >
                                                                Clear Signature
                                                        </button>
                                                        <button
                                                                type='button'
                                                                className='btn btn-primary'
                                                                onClick={save}
                                                        >
                                                                Save Signature
                                                        </button>
                                                </div>
                                                <button
                                                        type='submit'
                                                        className='btn btn-success'
                                                        style={{ marginTop: "40px", marginBottom: "60px" }}
                                                >
                                                        Submit Essay & Cost of 1 Page: $30
                                                </button>
                                        </form>
                                </div>

                        </div>

                        <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>

                        </div>

                </div>
        )

}

export default EssaySubmissionForm