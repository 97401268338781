import React from 'react'

function HomePageMissionQuote() {

    return(
        <div className='container-fluid' style={{ padding: "0px" }}>

            <div className='row'>
                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-10'>
                    <hr style={{ width: "95%", margin: "auto" }}></hr>
                    <div className='row'>
                    <div className='col-2'></div>
                    <div className='col-8'>
                        <p className='responsive-text' 
                        style={{ 
                            textAlign: "center", 
                            marginTop: "20px", 
                            marginBottom: "20px",
                            marginLeft: "2%",
                            marginRight: "2%" 
                            }}>
                            <i>MLC's mission is for students to gain confidence and independence to complete the given task, 
                            activity, subject or grade by taking ownership of their learning through individualized 
                            interaction and guidance.</i>
                        </p>
                    </div>
                    <div className='col-2'></div>
                    </div>
                    <hr style={{ width: "95%", margin: "auto" }}></hr>
                </div>
                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

        </div>
    )
}

export default HomePageMissionQuote