import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import getStripe from '../GetStripe';

import "../css/ReadingEvaluationForm.css"

function ReadingEvaluationForm() {
        
        const sigCanvas = useRef({});

        const [formData, setFormData] = useState({
                studentFullName: '',
                studentDateOfBirth: '',
                studentGrade: '',
                parentGuardianFullName: '',
                parentGuardianEmail: '',
                parentGuardianNumber: '',
                thursdaysAt4: '',
                cancellationPolicy: ''
        })

        const clear = () => {
                sigCanvas.current.clear();
        }

        const save = () => {
                const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
                console.log(signature); 
        };

        async function handleCheckout(event) {
                event.preventDefault();
                const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
                console.log('Form Data: ', formData)
                const completeFormData = {
                        ...formData,
                        signature
                };
                console.log('Complete Form Data: ', completeFormData);
                try {
                        const response = await fetch('https://myliteracycenter.pythonanywhere.com/forms/reading-evaluation/', {
                                method: 'POST',
                                headers: {
                                        'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(completeFormData)
                        });
                
                        if (response.ok) {
                                console.log('Form submitted successfully');
                                alert("Thank you; your form was submitted successfully!")
                        } else {
                                console.error('Error submitting form');
                        }
                } catch (error) {
                        console.error('Error submitting form:', error);
                }
                const stripe = await getStripe();
                const { error } = await stripe.redirectToCheckout({
                        lineItems: [{ price: 'price_1PskTaRvLFdAUSWUq6vFYgog', quantity: 1 }],
                        mode: 'payment',
                        successUrl: window.location.origin + '/',
                        cancelUrl: window.location.origin + '/',
                        customerEmail: formData.parentGuardianEmail,
                });
            
                if (error) {
                        console.error('Checkout error:', error.message);
                        alert("Checkout error: " + error.message);
                }
        }    

        const handleChange = (event) => {
                const { name, value, type, checked } = event.target;
                setFormData(prevState => ({
                    ...prevState,
                    [name]: type === 'checkbox' ? checked : value
                }));
            };    

        return (
                <div className="container-fluid" style={{ padding: "0px" }}>
                        
                        <div className="row">

                                <div className="col-1" style={{ backgroundColor: "#E5E4E2" }}></div>

                                <div className="col-10">

                                        <div className="row" style={{ paddingTop: "20px", paddingBottom: "40px" }}>

                                                <hr 
                                                        style={{ 
                                                                width: "90%", 
                                                                margin: "auto", 
                                                                height: "5px",
                                                                border: "none",
                                                                backgroundColor: "#36454F",
                                                                opacity: "1"
                                                        }}
                                                ></hr>
                                                <h1 
                                                        className="responsive-h1 ReadingEvaluationForm__form-title" 
                                                        style={{ paddingTop: "30px", paddingBottom: "10px" }}
                                                >
                                                        Comprehensive Reading Evaluation
                                                </h1>
                                                <p className="responsive-text-small ReadingEvaluationForm__text-margins">
                                                        Reading is an integral part of the learning journey. When a student struggles in reading and writing, 
                                                        having an evaluation enables parents and the student to know which areas need more focus.
                                                </p>
                                                <p className="responsive-text-small ReadingEvaluationForm__text-margins">
                                                        The assessment comprises of 3 components: Fluency, Comprehension, and Writing. This Comprehensive 
                                                        Reading Evaluation is done in person one-on-one and may take up to 50 minutes to complete.
                                                </p>
                                                <p className="responsive-text-small ReadingEvaluationForm__text-margins">
                                                        At this time, we are only able to conduct tests on Thursdays at 4pm. You may choose a date to have your 
                                                        child's evaluation, though please wait for the confirmation email from zainab@myliteracycenter.org.
                                                </p>

                                                <form 
                                                        onSubmit={handleCheckout} 
                                                        style={{
                                                                paddingLeft: "10%",
                                                                paddingRight: "10%"
                                                        }}
                                                >
                                                        <div className="form-group">
                                                                <label htmlFor="studentFullName" className="form-text">Student Full Name</label>
                                                                <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="studentFullName"
                                                                        name="studentFullName"
                                                                        value={formData.studentFullName}
                                                                        onChange={handleChange}
                                                                />
                                                        </div>
                                                        <div className="row" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                                                <div className="col-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="studentDateOfBirth" className="form-text">Student Date of Birth</label>
                                                                                <input
                                                                                        type="date"
                                                                                        className="form-control"
                                                                                        id="studentDateOfBirth"
                                                                                        name="studentDateOfBirth"
                                                                                        value={formData.studentDateOfBirth}
                                                                                        onChange={handleChange}
                                                                                />
                                                                        </div>
                                                                </div>
                                                                <div className="col-6">
                                                                        <div className="form-group">
                                                                                <label htmlFor="studentGrade" className="form-text">Student Grade</label>
                                                                                <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        name="studentGrade"
                                                                                        id="studentGrade"
                                                                                        value={formData.studentGrade}
                                                                                        onChange={handleChange}
                                                                                />
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className='form-group'>
                                                                <label htmlFor='parentGuardianFullName' className='form-text'>Parent/Guardian Full Name</label>
                                                                <input 
                                                                        type="text"
                                                                        className='form-control'
                                                                        name='parentGuardianFullName'
                                                                        id='parentGuardianFullName'
                                                                        value={formData.parentGuardianFullName}
                                                                        onChange={handleChange}
                                                                />
                                                        </div>
                                                        <div className='row' style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                                                <div className='col-6'>
                                                                        <div className='form-group'>
                                                                                <label htmlFor='parentGuardianEmail' className='form-text'>Parent Guardian Email</label>
                                                                                <input
                                                                                        type='email'
                                                                                        name='parentGuardianEmail'
                                                                                        id='parentGuardianEmail'
                                                                                        value={formData.parentGuardianEmail}
                                                                                        className='form-control'
                                                                                        onChange={handleChange}
                                                                                />
                                                                        </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                        <div className='form-group'>
                                                                                <label htmlFor='parentGuardianNumber' className='form-text'>Parent Guardian Number</label>
                                                                                <input
                                                                                        type='tel'
                                                                                        name='parentGuardianNumber'
                                                                                        id='parentGuardianNumber'
                                                                                        value={formData.parentGuardianNumber}
                                                                                        onChange={handleChange}
                                                                                        className='form-control'
                                                                                />
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div className='form-group' style={{ paddingBottom: "20px" }}>
                                                                <label htmlFor='thursdaysAt4' className='form-text'>Thursdays at 4pm</label>
                                                                <input
                                                                        type="date"
                                                                        name="thursdaysAt4"
                                                                        onChange={handleChange}
                                                                        className='form-control'
                                                                        id='thursdaysAt4'
                                                                        value={formData.thursdaysAt4}
                                                                />
                                                        </div>
                                                        <div className='form-group'>
                                                                <div className='form-check'>
                                                                        <input
                                                                                className='form-check-input'
                                                                                type='checkbox'
                                                                                id='cancellationPolicy'
                                                                                onChange={handleChange}
                                                                                name="cancellationPolicy"
                                                                                checked={formData.cancellationPolicy}
                                                                                required
                                                                        />
                                                                        <label className='form-check-label' htmlFor='cancellationPolicy'>
                                                                                I understand that I will not receive a refund if I am unable to attend the scheduled 
                                                                                date. I can reschedule up to 2 times before my registration becomes invalid. I will 
                                                                                have to submit a new form and payment to schedule a Reading Evaluation.
                                                                        </label>
                                                                </div>
                                                        </div>
                                                        <div className='form-group' style={{ paddingTop: "20px" }}>
                                                                <label className='form-text'>Signature:</label>
                                                                <SignatureCanvas
                                                                        penColor='black'
                                                                        canvasProps={{className: "signatureCanvas"}}
                                                                        ref={sigCanvas}
                                                                />
                                                                <button
                                                                        type='button'
                                                                        className='btn btn-secondary'
                                                                        onClick={clear}
                                                                        style={{ marginRight: "10px" }}
                                                                >
                                                                        Clear Signature
                                                                </button>
                                                                <button type="button" className="btn btn-primary" onClick={save}>Save Signature</button>
                                                        </div>
                                                        <button
                                                                type='submit'
                                                                className='btn btn-success'
                                                                style={{ marginTop: "40px" }}
                                                        >
                                                                Submit and Pay $60
                                                        </button>
                                                </form>

                                        </div>

                                </div>

                                <div className="col-1" style={{ backgroundColor: "#E5E4E2" }}></div>

                        </div>

                </div>
        )

}

export default ReadingEvaluationForm