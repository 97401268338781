import React from "react";

import "../css/LiteratureCorner.css"

function LiteratureCorner() {

        return (
                <div className="container-fluid" style={{ padding: "0" }}>

                        <div className="row">

                                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>

                                <div className="col-10">

                                        <div className="row" style={{ paddingTop: "20px", paddingBottom: "60px" }}>

                                                <h1 className="responsive-h1 form-title">Online Resources</h1>

                                                <div className="col-1"></div>

                                                <div className="col-5">
                                                        <div className="LiteratureCorner__book-outline">
                                                                <div className="LiteratureCorner__book-outline-margins">
                                                                        <p className="responsive-text"><strong>On Race</strong></p>
                                                                        <p className="responsive-text-small"><strong>Picture Books</strong></p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>A Girl Like Me</i> by Angela Johnson</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Black is a Rainbow Color</i> by Angela Joy</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Hands Up!</i> by Breanna J. McDaniel</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>I Don't See Color</i> by G Todd Taylor</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Little Polar Bear</i> by Hans de Beer</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>My Hair is a Garden</i> by Cozbi A. Cabrera</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>The Colors of Us</i> by Karen Katz</p>
                                                                </div>
                                                        </div>
                                                        <div className="LiteratureCorner__book-outline" style={{ marginTop: "20px" }}>
                                                                <div className="LiteratureCorner__book-outline-margins">
                                                                        <p className="responsive-text"><strong>Poetry</strong></p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>I am Enough</i> by Grace Byers</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>I've Loved You Since Forever</i> by Hoda Kotb</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>The New Kid on the Block</i> by Jack Prelutsky</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>The Random House Book of Poetry for Children</i> by Jack Prelutsky</p>
                                                                </div>
                                                        </div>
                                                        <div className="LiteratureCorner__book-outline" style={{ marginTop: "20px" }}>
                                                                <div className="LiteratureCorner__book-outline-margins">
                                                                        <p className="responsive-text"><strong>Poetry</strong></p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Aesop Fables</i> by Aesop</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Fantastic Mr. Fox</i> by Roald Dahl</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>The Giving Tree</i> by Shel Silverstein</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}>The '<i>I Survived</i>' Series by Lauren Tarshis</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>The Stories Julian Tells</i> by Ann Cameron</p>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="col-5">
                                                        <div className="LiteratureCorner__book-outline">
                                                                <div className="LiteratureCorner__book-outline-margins">
                                                                        <p className="responsive-text"><strong>Book to Movie</strong></p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Alice's Adventures in Wonderland</i> by Lewis Carroll</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>A Wrinkle in Time</i> by Madeleine L'Engle</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Bridge to Terabithia</i> by Katherine Paterson</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Charlotte's Web</i> by E.B. White</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Holes</i> by Louis Sachar</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>James and the Giant Peach</i> by Roald Dahl</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Matilda</i> by Roald Dahl</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>The Giver</i> by Lois Lowry</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}>The '<i>Harry Potter</i>' Series by J.K. Rowling </p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>The Lion, the Witch, and the Wardrobe</i> by C.S. Lewis</p>
                                                                </div>
                                                        </div>
                                                        <div className="LiteratureCorner__book-outline" style={{ marginTop: "20px" }}>
                                                                <div className="LiteratureCorner__book-outline-margins">
                                                                        <p className="responsive-text"><strong>Books</strong></p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Born a Crime</i> by Trevor Noah</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>Stamped: Racism, Antiracism, and You</i> by Ibram X. Kendi and Jason Reynolds</p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}><i>The Jungle Book</i> by Rudyard Kipling</p>
                                                                        <p className="responsive-text" style={{ marginTop: "20px" }}><strong>Articles</strong></p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}>
                                                                                <a href="chrome-extension://oemmndcbldboiebfnladdacbdfmadadm/https://www.myliteracycenter.org/_files/ugd/d75f19_7b4d3a37eeca4596960a9f453281d649.pdf">
                                                                                        <i>Positive Parenting Tips-Preschoolers</i>
                                                                                </a>
                                                                        </p>
                                                                        <p className="responsive-text-small" style={{ marginBottom: "0px" }}>
                                                                                <a href="chrome-extension://oemmndcbldboiebfnladdacbdfmadadm/https://www.myliteracycenter.org/_files/ugd/d75f19_4413ca22506f41f1b3ba4af11fda5914.pdf">
                                                                                        <i>Positive Parenting Tips-Young Teens</i>
                                                                                </a>
                                                                        </p>
                                                                </div> 
                                                        </div>
                                                </div>

                                                <div className="col-1"></div>

                                        </div>

                                </div>

                                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>

                        </div>

                </div>
        )

}

export default LiteratureCorner