import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import "../css/TutoringApplication.css"
import getStripe from '../GetStripe';

const tutoringServices = [
    { value: "light", label: "Light ($400/8 sessions)" },
    { value: "bright", label: "Bright ($500/8 sessions)" },
    { value: "brilliant", label: "Brilliant ($600/8 sessions)" }
]

function TutoringApplication() {

    const sigCanvas = useRef({});

    const [formData, setFormData] = useState({
        studentFullName: '',
        contactNumber: '',
        studentDOB: '',
        parentGuardianFullName: '',
        parentGuardianEmail: '',
        address: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        tutoringService: '',
        cancellationPolicy: '',
        subjectArea: ''
    });

    const clear = () => {
        sigCanvas.current.clear();
    };

    const save = () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        console.log(signature)
    };

    async function handleCheckout(event) {
        event.preventDefault();
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        console.log('Form Data: ', formData)
        const completeFormData = {
                ...formData,
                signature
        };
        console.log('Complete Form Data: ', completeFormData);
        try {
                const response = await fetch('https://myliteracycenter.pythonanywhere.com/forms/tutoring-application/', {
                        method: 'POST',
                        headers: {
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(completeFormData)
                });
        
                if (response.ok) {
                        console.log('Form submitted successfully');
                        alert("Thank you; your form was submitted successfully!")
                } else {
                        console.error('Error submitting form');
                }
        } catch (error) {
                console.error('Error submitting form:', error);
        }
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{ price: 'price_1PDKpURvLFdAUSWUIA0yk2Cr', quantity: 1 }],
          mode: 'payment',
          successUrl: window.location.origin + '/',
          cancelUrl: window.location.origin + '/',
          customerEmail: formData.parentGuardianEmail,
        });
    
        if (error) {
            console.error('Checkout error:', error.message);
            alert("Checkout error: " + error.message);
        }
    }    

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };    

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        
        setFormData(prevState => {
            if (checked) {
                return {
                    ...prevState,
                    subjectArea: [...prevState.subjectArea, value]
                };
            } else {
                return {
                    ...prevState,
                    subjectArea: prevState.subjectArea.filter(area => area !== value)
                };
            }
        });
    };    

    return(
        <div className="container-fluid" style={{ padding: "0px" }}>

            <div className="row">
                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-10 d-flex justify-content-center align-items-center'>

                    <div className="row flex-grow-1" style={{ paddingTop: "20px", paddingBottom: "60px" }}>
                        <hr 
                            style={{ 
                                width: "90%", 
                                margin: "auto", 
                                height: "5px",
                                border: "none",
                                backgroundColor: "#36454F",
                                opacity: "1"
                            }}
                        ></hr>
                        <h1 
                            className="responsive-h1 form-title" 
                            style={{ paddingTop: "60px", paddingBottom: "30px" }}
                        >
                            Tutoring Application
                        </h1>
                        <form onSubmit={handleCheckout} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                            <div className="form-group">
                                <label htmlFor="studentFullName" className="form-text">Student Full Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="studentFullName" 
                                    name="studentFullName"
                                    value={formData.studentFullName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="row" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="studentDOB" className="form-text">Student Date of Birth</label>
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            id="studentDOB"
                                            name="studentDOB"
                                            value={formData.studentDOB}
                                            onChange={handleChange} 
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="contactNumber" className="form-text">Contact Number</label>
                                        <input type="tel" onChange={handleChange} className="form-control" name='contactNumber' id="contactNumber" value={formData.contactNumber} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ paddingBottom: "20px" }}>
                                <label htmlFor="parentGuardianFullName" className="form-text">Parent/Guardian Full Name</label>
                                <input type="text" onChange={handleChange} className="form-control" name="parentGuardianFullName" id="parentGuardianFullName" value={formData.parentGuardianFullName} />
                            </div>
                            <div className="form-group" style={{ paddingBottom: "20px" }}>
                                <label htmlFor="parentGuardianEmail" className="form-text">Parent/Guardian Email</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    id="parentGuardianEmail" 
                                    name="parentGuardianEmail" 
                                    value={formData.parentGuardianEmail}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group" style={{ paddingBottom: "20px" }}>
                                <label htmlFor="address" className="form-text">Streed Address</label>
                                <input type="text" onChange={handleChange} className="form-control" name="address" id="address" value={formData.address} />
                            </div>
                            <div className="form-group" style={{ paddingBottom: "20px" }}>
                                <label htmlFor="addressLine2" className="form-text">Address Line 2</label>
                                <input type="text" onChange={handleChange} className="form-control" name="addressLine2" id="addressLine2" value={formData.addressLine2} />
                            </div>
                            <div className="row" style={{ paddingBottom: "20px" }}>
                                <div className="col-6">
                                    <label htmlFor="city" className="form-text">City</label>
                                    <input type="text" onChange={handleChange} className="form-control" name="city" id="city" value={formData.city} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="state" className="form-text">State</label>
                                    <input type="text" onChange={handleChange} className="form-control" name="state" id="state" value={formData.state} />
                                </div>
                            </div>
                            <div className="row" style={{ paddingBottom: "20px" }}>
                                <div className="col-6">
                                    <label htmlFor="zip" className="form-text">Postal / Zip Code</label>
                                    <input type="number" onChange={handleChange} className="form-control" name="zip" id="zip" value={formData.zip} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="country" className="form-text">Country</label>
                                    <input type="text" onChange={handleChange} className="form-control" name="country" id="country" value={formData.country} />
                                </div>
                            </div>
                            <div className="form-group" style={{ paddingBottom: "20px" }}>
                                <label htmlFor="tutoringService" className="form-text">Tutoring Service:</label>
                                <select className="form-control" id="tutoringService" onChange={handleChange} name="tutoringService" value={formData.tutoringService}>
                                    <option value="">- Select -</option>
                                    {tutoringServices.map(service => (
                                        <option key={service.value} value={service.value}>{service.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="form-text">Subject/Content Areas for Tutoring</label>
                                <div>
                                    <div className="form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            id="mathTutoring" 
                                            name="tutoringAreas" 
                                            value="KG-2nd Reading/Writing"
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="mathTutoring">
                                            KG-2nd Reading/Writing
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            id="scienceTutoring" 
                                            name="tutoringAreas" 
                                            onChange={handleCheckboxChange}
                                            value="3rd-8th Reading/Writing"
                                        />
                                        <label className="form-check-label" htmlFor="scienceTutoring">
                                            3rd-8th Reading/Writing
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            id="scienceTutoring" 
                                            name="tutoringAreas" 
                                            onChange={handleCheckboxChange}
                                            value="9th-12th English"
                                        />
                                        <label className="form-check-label" htmlFor="scienceTutoring">
                                            9th-12th English
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            id="scienceTutoring" 
                                            name="tutoringAreas" 
                                            onChange={handleCheckboxChange}
                                            value="KG-2nd Math"
                                        />
                                        <label className="form-check-label" htmlFor="scienceTutoring">
                                            KG-2nd Math
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            id="scienceTutoring" 
                                            name="tutoringAreas" 
                                            onChange={handleCheckboxChange}
                                            value="3rd-5th Math"
                                        />
                                        <label className="form-check-label" htmlFor="scienceTutoring">
                                            3rd-5th Math
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ paddingTop: "20px" }}>
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="cancellationPolicy" 
                                        onChange={handleChange}
                                        name="cancellationPolicy" 
                                        checked={formData.cancellationPolicy}
                                        required
                                    />
                                    <label className="form-check-label" htmlFor="cancellationPolicy">
                                        I understand I will be charged when cancelling sessions or arriving late without 1 hour notice.
                                    </label>
                                </div>
                            </div>
                            <div className="form-group" style={{ paddingTop: "20px" }}>
                                <label className='form-text'>Signature:</label>
                                <SignatureCanvas
                                    penColor='black'
                                    canvasProps={{className: 'signatureCanvas'}}
                                    ref={sigCanvas}
                                />
                                <button 
                                    type="button" 
                                    className="btn btn-secondary" 
                                    onClick={clear}
                                    style={{ marginRight: "10px" }}
                                >
                                    Clear Signature
                                </button>
                                <button type="button" className="btn btn-primary" onClick={save}>Save Signature</button>
                            </div>
                            <button 
                                type="submit" 
                                className="btn btn-success"
                                style={{ marginTop: "40px" }}
                            >
                                Submit Application & $20
                            </button>
                        </form>
                    </div>

                </div>
                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

        </div>
    )
}

export default TutoringApplication