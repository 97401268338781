import React, { useState } from 'react';
import getStripe from '../GetStripe';
import "../css/WorkbooksForm.css"

const workbooks = [
    { value: "science", label: "Science K-2" },
    { value: "puzzle", label: "Puzzle Words PreK-KG" },
    { value: "poetry", label: "Poetry 2-3" }
]

function WorkbooksForm() {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        workbook: '',
        cancellationPolicy: ''
    })

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };  

    async function handleCheckout(event) {
        event.preventDefault();
        console.log('Form Data: ', formData)
        const completeFormData = {
                ...formData
        };
        console.log('Complete Form Data: ', completeFormData);
        try {
                const response = await fetch('https://myliteracycenter.pythonanywhere.com/forms/order-workbooks/', {
                        method: 'POST',
                        headers: {
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(completeFormData)
                });
        
                if (response.ok) {
                        console.log('Form submitted successfully');
                        alert("Thank you; your form was submitted successfully!")
                } else {
                        console.error('Error submitting form');
                }
        } catch (error) {
                console.error('Error submitting form:', error);
        }
        const stripe = await getStripe()
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: 'price_1PskVTRvLFdAUSWUaR65KFc6', quantity: 1 }], 
            mode: 'payment',
            successUrl: window.location.origin + '/',
            cancelUrl: window.location.origin + '/',
            customerEmail: formData.email,
        })

        if (error) {
            console.error('Checkout error: ', error.message)
            alert("Checkout error: " + error.message)
        }
    }

    return (
        <div className='container-fluid' style={{ padding: "0px" }}>

            <div className='row'>
                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-10 justify-content-center align-items-center'>

                    <div className="row flex-grow-1" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                        <h1 
                            className="responsive-h1 form-title" 
                            style={{ paddingBottom: "20px" }}
                        >
                            MLC Workbooks
                        </h1>
                    </div>

                    <div className='row flex-grow-1'>
                        <div className='col-4'></div>
                        <div className='col-4' style={{ backgroundColor: "#E5E4E2" }}>
                            <h2 className='responsive-h2 workbook-form-title' style={{ paddingTop: "38px", paddingBottom: "10px" }}>Order Workbooks</h2>
                            <form onSubmit={handleCheckout} style={{ paddingLeft: "10%", paddingRight: "10%"}}>
                                <div className='form-group'>
                                    <label htmlFor='firstName' className='form-text'>First Name</label>
                                    <input
                                        type='text'
                                        name='firstName'
                                        id='firstName'
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className='WorkbooksForm__form-control form-control'
                                    />
                                </div>
                                <div className='form-group' style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                    <label htmlFor='lastName' className='form-text'>Last Name</label>
                                    <input
                                        type='text'
                                        name='lastName'
                                        id='lastName'
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className='WorkbooksForm__form-control form-control'
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='email' className='form-text'>Email</label>
                                    <input
                                        type='email'
                                        name='email'
                                        id='email'
                                        className='WorkbooksForm__form-control form-control'
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='form-group' style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                    <label htmlFor='phone' className='form-text'>Phone</label>
                                    <input
                                        type='tel'
                                        name='phone'
                                        id='phone'
                                        style={{ width: "60%" }}
                                        className='WorkbooksForm__form-control form-control'
                                        onChange={handleChange}
                                        value={formData.phone}
                                    />
                                </div>
                                <div className="form-group" style={{ paddingBottom: "20px" }}>
                                    <label htmlFor="workbook" className="form-text">Choose a Workbook</label>
                                    <select className="WorkbooksForm__form-control form-control" id="workbook" onChange={handleChange} name="workbook" value={formData.workbook}>
                                        <option value="">Choose a workbook</option>
                                        {workbooks.map(item => (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group" style={{ paddingTop: "10px" }}>
                                    <div className="WorkbooksForm__form-check form-check">
                                        <input 
                                            className="WorkbooksForm__form-check-input form-check-input" 
                                            type="checkbox" 
                                            id="cancellationPolicy" 
                                            onChange={handleChange}
                                            name="cancellationPolicy" 
                                            checked={formData.cancellationPolicy}
                                            required
                                        />
                                        <label className="form-check-label responsive-text-xsmall" htmlFor="cancellationPolicy">
                                            I understand that I am purchasing this workbook for my child(ren) and agree to NOT share this document with others (friends, classmates, colleagues, etc.)
                                        </label>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: "80px" }}>
                                    <button 
                                        type="submit" 
                                        className="btn btn-success"
                                        style={{ 
                                            marginTop: "40px", 
                                            backgroundColor: "black",
                                            borderRadius: "0",
                                            border: "0px",
                                            width: "80%"
                                        }}
                                    >
                                        Order Now and Pay $15
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className='col-4'></div>
                    </div>

                </div>
                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

        </div>
    )

}

export default WorkbooksForm